/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "palsie/glad4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "palsie/glad4.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(relativePath: { eq: "palsie/plafond.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `M.Pals - Glad pleisterwerk voor het plafond.`,
  textWithLink: () => (
    <>
      <p>
        Pleisterwerk wordt glad gemaakt en afgewerkt door de stukadoor zodat de
        schilder plafonds netjes kan schilderen. Met glad pleisterwerk geeft u
        de woning een perfecte sfeer, uitstraling en is bovendien ook
        onderhoudsvriendelijk. Pleisterwerk kan worden aangebracht op wanden of
        plafonds in huis. Het stucwerk kan overal binnenshuis worden toegepast,
        mits er een goede ondergrond aanwezig is.
      </p>
      <div className='hidden-sm hidden-md hidden-lg' data-sal='slide-top'>
        <a href='tel:0629575549'>
          <Button
            role='button'
            variant='contained'
            className='btn heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs' data-sal='slide-top'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/plafondafwerking'>
        <Button
          role='button'
          variant='contained'
          className='btn heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const GladPleisterwerk = ({ data, path, location }) => {
  const seo = {
    title: `Glad pleisterwerk op uw plafond`,
    description: `Voorzie je huist van glad pleisterwerk voor de plafond en krijg optimaal resultaat`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'M.Pals Stukadoors',
    imgTitle: 'M.Pals Stukadoors',
    header: 'M.Pals - Glad pleisterwerk op uw plafond',
    inner:
      'Een pleisterlaag levert een gladde ondergrond voor vrijwel alles: sierpleister, spachtelputz, verf, behang, etc. Pleisterwerk kan bijvoorbeeld gebruikt worden als strakke afwerking van uw woon- en slaapkamers, maar is ook geschikt voor de afwerking van vochtige ruimtes zoals badkamers en kelders. Net als raapwerk is pleisterwerk vochtregulerend en heeft het brandvertragende eigenschappen. Na de oplevering van het stukwerk kunt u de muren schilderen of behangen',
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Glad pleisterwerk`,
    pitchLine: `Voorzie je huist van glad pleisterwerk voor de plafond en krijg prachtig resultaat voor optimaal woon plezier.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

GladPleisterwerk.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default GladPleisterwerk;
